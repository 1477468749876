body {
  overflow-x: hidden;
  display: -webkit-flex;
  display: flex;
  margin-bottom: -100px;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root {
  position: relative;
  min-width: 100vw;
  background-image: url(/static/media/traffic.d26b2158.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.backgroundColor {
  border: 8px solid #4285f4;
  background: lightblue;
  padding: 2rem;
  margin: 0 auto 6rem;
}

#responsive-navbar-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.navbar-nav {
  margin-right: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.divider {
  width: 5px;
  height: auto;
  display: inline-block;
}

i {
  color: white;
  font-size: 30px;
}

.map {
  margin: 5em auto;
  width: 75%;
  height: 65vh;
}


.map {
  position: relative;
}

footer {
  padding-top: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  border-top: 8px solid #4285f4;
  background-color: lightblue;
  text-align: center;
  width: 100%;
  height: 65px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}


.backgroundColor.containerdiv {
  border: 8px solid #4285f4;
  background: lightblue;
  padding: 2rem;
  margin: 5rem auto 6rem;
  text-align: center;
}

.backgroundColor {
  border: 8px solid #4285f4;
  background: lightblue;
  padding: 2rem;
  margin: 3rem auto 6rem;
  width: 275;
}

.username {
  text-transform: capitalize;
}

#root {
  min-height: 100vh;
}

.newuser {
  padding: 2rem;
}

.container.backgroundcolor.reportsearchform {
  background-color: lightblue;
  border: 8px solid #4285f4;
  height: 300px;
  margin: 3rem auto 6rem;
} 
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

