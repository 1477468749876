.map {
  position: relative;
}

footer {
  padding-top: 8px;
  display: flex;
  justify-content: center;
  border-top: 8px solid #4285f4;
  background-color: lightblue;
  text-align: center;
  width: 100%;
  height: 65px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
