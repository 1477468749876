body {
  overflow-x: hidden;
  display: flex;
  margin-bottom: -100px;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root {
  position: relative;
  min-width: 100vw;
  background-image: url("../src/img/traffic.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.backgroundColor {
  border: 8px solid #4285f4;
  background: lightblue;
  padding: 2rem;
  margin: 0 auto 6rem;
}
