.backgroundColor.containerdiv {
  border: 8px solid #4285f4;
  background: lightblue;
  padding: 2rem;
  margin: 5rem auto 6rem;
  text-align: center;
}

.backgroundColor {
  border: 8px solid #4285f4;
  background: lightblue;
  padding: 2rem;
  margin: 3rem auto 6rem;
  width: 275;
}
