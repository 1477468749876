#responsive-navbar-nav {
  display: flex;
  flex-shrink: 1;
  justify-content: flex-start;
}

.navbar-nav {
  margin-right: 2rem;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.divider {
  width: 5px;
  height: auto;
  display: inline-block;
}

i {
  color: white;
  font-size: 30px;
}
